import Polyglot from "node-polyglot";

export const buildTranslationFormatter = (language, phrases) => {
  const locale = language.toLowerCase();
  const polyglot = new Polyglot({
    locale,
    phrases,
    onMissingKey: (key, args, language) => {
      throw new Error(
        `No translations for "${key}" with arguments ${JSON.stringify(
          args
        )} in ${language} locale.`
      );
    }
  });

  return (...args) => polyglot.t(...args);
};
