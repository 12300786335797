import { PropTypes } from "prop-types";
import withTranslation from "@template/components/translation";
import styles from "./SearchResultsCountAnnouncer.css";

const SearchResultsCountAnnouncer = ({
  shouldAnnounce,
  count,
  formatTranslation
}) => (
  <div className={styles.announcer} aria-live="polite">
    {shouldAnnounce &&
      formatTranslation("searchbar_result_count", {
        smart_count: count
      })}
  </div>
);

SearchResultsCountAnnouncer.propTypes = {
  shouldAnnounce: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(SearchResultsCountAnnouncer);
