import { Component } from "react";
import PropTypes from "prop-types";
import withTranslation from "@template/components/translation";
import Button from "@src/template/components/Button";
import { getContext } from "@src/client/context";
import setRsftWelcomeMessageClosed from "./setRsftWelcomeMessageClosed";
import {
  RSFT_DELIVER_TO_DELIVERY_COUNTRY_REFERRAL_NAME,
  RSFT_DELIVER_ELSEWHERE_REFERRAL_NAME,
  RSFT_ANALYTICS_IMPRESSION_DELAY
} from "@src/template/components/WelcomeMessage/RsftWelcomeMessage/constants";
import {
  trackPageInteraction,
  ANALYTICS_CONTEXT,
  ANALYTICS_INTERACTION_IMPRESSION
} from "@src/helpers/eventing/events";
import styles from "./index.css";

class RsftWelcomeMessage extends Component {
  state = {
    getTranslations: null,
    userTookAction: false
  };

  async componentDidMount() {
    const getTranslations = (
      await import(/* webpackChunkName: "translations" */ "@src/../i18n")
    ).default;

    this.setState({
      getTranslations
    });

    this.trackImpression();
  }

  handleButtonClick = countryCode => () => {
    const { openCountrySelector } = this.props;
    const RIGHT_SITE_FIRST_TIME_REFERRAL_NAME = countryCode
      ? RSFT_DELIVER_TO_DELIVERY_COUNTRY_REFERRAL_NAME
      : RSFT_DELIVER_ELSEWHERE_REFERRAL_NAME;

    countryCode
      ? openCountrySelector(countryCode, RIGHT_SITE_FIRST_TIME_REFERRAL_NAME)
      : openCountrySelector(undefined, RIGHT_SITE_FIRST_TIME_REFERRAL_NAME);

    setRsftWelcomeMessageClosed();
    this.setState({ userTookAction: true });
  };

  trackImpression = () => {
    window.setTimeout(() => {
      const context = getContext();
      const currentBrowseCountry = context.getBrowseCountry();
      const currentCurrency = context.getBrowseCurrency();
      const deliveryCountry = this.props.country.countryCode;

      const trackOptions = {
        context: ANALYTICS_CONTEXT.welcomeMessage,
        interaction: ANALYTICS_INTERACTION_IMPRESSION,
        properties: {
          deliveryCountry,
          currentBrowseCountry,
          currentCurrency
        }
      };

      trackPageInteraction(trackOptions);
    }, RSFT_ANALYTICS_IMPRESSION_DELAY);
  };

  render() {
    const { getTranslations, userTookAction } = this.state;

    if (!getTranslations || userTookAction) {
      return null;
    }

    const {
      formatTranslation,
      country: {
        imageUrl,
        defaultLanguage,
        nameTranslatedToBrowseCountryLocale,
        name: countryName,
        countryCode
      }
    } = this.props;

    const phrasesFromSecondLanguage = getTranslations(defaultLanguage);
    const context = getContext();
    const currentBrowseLanguage = context.getBrowseLanguage();
    const isPrimaryLanguageMatch =
      defaultLanguage.substring(0, 2) === currentBrowseLanguage.substring(0, 2);

    return (
      <div
        className={styles.container}
        role="region"
        aria-labelledby="welcomeMessageText"
        data-testid="welcome-message"
      >
        <div className={styles.mvtWrapper} role="alert" aria-live="assertive">
          <div className={styles.primaryCopyWrapper}>
            <img
              src={imageUrl}
              alt=""
              className={styles.flag}
              data-testid="icon"
            />
            <p className={styles.primaryCopy} id="welcomeMessageText">
              {phrasesFromSecondLanguage[
                "onboarding_web_delivery_country_suggestion_message"
              ].replace("%{geocountry}", countryName)}
            </p>
          </div>
          {!isPrimaryLanguageMatch && (
            <p className={styles.secondaryCopy}>
              {formatTranslation(
                "onboarding_web_delivery_country_suggestion_message",
                {
                  geocountry: nameTranslatedToBrowseCountryLocale
                }
              )}
            </p>
          )}
          <div className={styles.buttonsContainer}>
            <Button
              theme="primary"
              truncateText
              onClick={this.handleButtonClick(countryCode)}
            >
              {formatTranslation(
                "onboarding_web_delivery_country_suggestion_cta_geo",
                {
                  geocountry: nameTranslatedToBrowseCountryLocale
                }
              )}
            </Button>
            <span className={styles.separator}>
              {formatTranslation("delivery_identifier_or")}
            </span>
            <Button
              theme="secondary"
              truncateText
              onClick={this.handleButtonClick()}
            >
              {formatTranslation(
                "onboarding_web_delivery_country_suggestion_cta_other",
                {
                  geocountry: nameTranslatedToBrowseCountryLocale
                }
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

RsftWelcomeMessage.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  country: PropTypes.shape({
    countryCode: PropTypes.string.isRequired,
    defaultLanguage: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    nameTranslatedToBrowseCountryLocale: PropTypes.string.isRequired
  }).isRequired,
  openCountrySelector: PropTypes.func.isRequired
};

export default withTranslation(RsftWelcomeMessage);
