import { connect } from "react-redux";
import {
  getDefaultLanguage,
  getPhrases
} from "../../state/modules/regionalStore";

const mapStateToProps = state => ({
  language: getDefaultLanguage(state),
  phrases: getPhrases(state)
});

export const addLanguage = Component => connect(mapStateToProps)(Component);
