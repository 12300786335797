import PropTypes from "prop-types";
import withTokenGroup from "../withTokenGroup";
import withTranslation from "../translation";
import styles from "./index.css";
import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace,
  siteChromeInitialBreadcrumbs
} from "@template/constants";

const namespace = `window.${asosGlobalNamespace}.${siteChromeGlobalNamespace}.${siteChromeInitialBreadcrumbs}`;

const Breadcrumbs = ({ formatTranslation }) => (
  <nav
    aria-label={formatTranslation("accessibility_breadcrumbs")}
    className={styles.container}
    dangerouslySetInnerHTML={{
      __html: `{{#if Breadcrumbs}}
          <script data-id="${namespace}" type="application/json">
            [{{#each Breadcrumbs}}{ {{#if Url}}"Url": "{{Url}}", {{/if}}"Label": "{{Label}}" }{{#if Last}}{{#else}},{{/if}}{{/each}}]
          </script>
          <script>
            ${namespace} = JSON.parse(document.querySelector(\`script[type="application/json"][data-id="${namespace}"]\`).textContent);
          </script>
          <ol class="${styles.list}">
            {{#each Breadcrumbs}}
              <li>
                {{#if @index}}<span aria-hidden="true">›</span>{{/if}}
                {{#if Url}}
                  <a href="{{Url}}">{{Label}}</a>
                {{#else}}
                  {{Label}}
                {{/if}}
              </li>
            {{/each}}
          </ol>
        {{/if}}`
    }}
  />
);

Breadcrumbs.propTypes = {
  formatTranslation: PropTypes.func.isRequired
};

const breadcrumbWithTokenGroup = withTokenGroup(
  "breadcrumb",
  null,
  Breadcrumbs
);

export default withTranslation(breadcrumbWithTokenGroup);
