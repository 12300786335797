/* eslint-disable no-underscore-dangle */
import { StrictMode } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import "@template/styles/fonts.css";
import "@template/styles/global.css";

import clientConfig from "@template/clientConfig";
import configureStore from "@template/state";
import contentTargeting from "@src/helpers/contentTargetingEvents";
import { eventBus } from "@src/helpers/eventing/events";
import loadTokenBlock from "@src/helpers/loadTokenBlock";
import { showBanner as showOneTrustCookieBanner } from "./oneTrust/showBanner";
import { openCountrySelector } from "@template/state/modules/countrySelector";
import setBreadcrumbs from "./breadcrumbs/setBreadcrumbs";

import {
  siteChromeInitialStore,
  siteChromeTokenValues,
  asosGlobalNamespace,
  siteChromeGlobalNamespace,
  sdkGlobalNamespace,
  manifestWindowKey,
  siteChromeInitialBreadcrumbs
} from "@template/constants";

import addFeaturesToStore from "./addFeaturesToStore";
import { context } from "./context";
import { reloadNavigation } from "./navigation";
import { initialise as initialiseSdk } from "./sdk";
import loadUserProfile from "./loadUserProfile";
import initialiseAnalytics from "./analytics";
import { MainContent, ModalContent } from "@template/components/App";
import { adobeDataLeakDebugger } from "./adobeDebugging";
import { setupPageDataEvent } from "./setupPageDataEvent";
import { optimizelyActivator } from "./optimizelyActivator";
import initialiseEventTrackingBridge from "@src/client/initialiseEventTrackingBridge";
import createModalNamespace from "@template/components/Modals/PublicModals/createModalNamespace";
import updateCountryCurrencyList from "@src/helpers/updateCountryCurrencyList";

adobeDataLeakDebugger(window.s);
optimizelyActivator();

/* <TODO>: remove once "asos-web-version-manifests" is updated to use window.asos._versionManifest (or otherwise) */
window[asosGlobalNamespace] = {
  ...window[asosGlobalNamespace],
  [manifestWindowKey]: window.__versionManifest
};
/* </TODO> */

const store = configureStore(
  window[asosGlobalNamespace][siteChromeGlobalNamespace][siteChromeInitialStore]
);

const {
  context: webContext,
  updateRegionalStore,
  pendingRedirect
} = context(store);

window[asosGlobalNamespace] = {
  ...window[asosGlobalNamespace],
  webContext,
  contentTargeting,
  eventBus,
  [sdkGlobalNamespace]: {}
};

// For the IE experiment https://asosmobile.atlassian.net/browse/WD-28026
webContext.getBrowseCountry() === "IE" && updateCountryCurrencyList(webContext);

createModalNamespace();

window.__sdk =
  window[asosGlobalNamespace][sdkGlobalNamespace]; /* backwards compatibility */
window.analyticsEventEmitter =
  window[asosGlobalNamespace].eventBus; /* backwards compatibility */
window.__siteChrome = {
  context: window[asosGlobalNamespace].webContext
}; /* backwards compatibility */

window[asosGlobalNamespace][siteChromeGlobalNamespace].openCountrySelector =
  openCountrySelector().bind(null, store.dispatch, store.getState);

window[asosGlobalNamespace][siteChromeGlobalNamespace].setBreadcrumbs =
  setBreadcrumbs;

setBreadcrumbs(
  window[asosGlobalNamespace][siteChromeGlobalNamespace][
    siteChromeInitialBreadcrumbs
  ]
);
delete window[asosGlobalNamespace][siteChromeGlobalNamespace][
  siteChromeInitialBreadcrumbs
];

initialiseEventTrackingBridge();
setupPageDataEvent();

const updateFeatures = addFeaturesToStore(store, eventBus);

eventBus.addListener("features-sdk-ready", () =>
  updateFeatures("features-sdk-ready")
);
eventBus.addListener("features-mvt-ready", () =>
  updateFeatures("features-mvt-ready")
);

const { NAVIGATION_API_PATH } = clientConfig.get();

export const updateStateWithClientData = () => {
  if (pendingRedirect) {
    return;
  }

  updateRegionalStore(() => {
    if (!NAVIGATION_API_PATH.includes("/preview/")) {
      reloadNavigation(store);
    }
  });

  loadUserProfile(store);

  initialiseAnalytics();

  const siteChromeNamespace =
    window[asosGlobalNamespace][siteChromeGlobalNamespace];

  loadTokenBlock({
    store,
    initialState: siteChromeNamespace[siteChromeInitialStore],
    newState: siteChromeNamespace[siteChromeTokenValues]
  });

  delete siteChromeNamespace[siteChromeInitialStore];

  initialiseSdk(store, webContext);

  showOneTrustCookieBanner(store);

  persistStore(store, {
    keyPrefix: "chrome:",
    whitelist: ["search"]
  });
};

export const ModalContentContainer = () => {
  if (pendingRedirect) {
    return null;
  }

  return (
    <StrictMode>
      <Provider store={store}>
        <ModalContent />
      </Provider>
    </StrictMode>
  );
};

export const MainContentContainer = () => {
  if (pendingRedirect) {
    return null;
  }

  return (
    <StrictMode>
      <Provider store={store}>
        <MainContent webContext={webContext.getWebContext()} />
      </Provider>
    </StrictMode>
  );
};
