import { Component } from "react";
import PropTypes from "prop-types";
import withTranslation from "../translation";
import FocusLock, { InFocusGuard } from "react-focus-lock";
import Fade from "@template/components/CountrySelector/Fade";
import Backdrop from "@template/components/CountrySelector/Backdrop";
import Form from "@template/components/CountrySelector/Form";
import Container from "@template/components/CountrySelector/Container";
import getAlternateUrls from "@template/helpers/getAlternateUrls";
import { SMALL_VIEWPORT } from "@template/state/modules/layout/constants";
import { BURGER_MENU_BUTTON_DATA_TEST_ID } from "@template/components/Burger/constants";
import styles from "./CountrySelector.css";
import {
  ProgressIndicator,
  SIZE_MEDIUM
} from "@asosteam/asos-web-component-library-progress-indicator";

class CountrySelector extends Component {
  constructor(props) {
    super(props);
    this.state = { firstOpen: true, alternateUrls: {} };
  }

  componentDidMount() {
    this.setState({ alternateUrls: getAlternateUrls() });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ lastActiveElement: document.activeElement });
    }
  }

  handleFocus = () => {
    const { lastActiveElement } = this.state;
    const { viewport, focusLastActiveElement } = this.props;

    if (viewport === SMALL_VIEWPORT) {
      focusLastActiveElement([], {
        forceElementFocus: `[data-testid="${BURGER_MENU_BUTTON_DATA_TEST_ID}"]`
      });
    } else {
      focusLastActiveElement([lastActiveElement]);
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    onClose();
    this.setState({ firstOpen: true });
  };

  onCountryChange = (country, defaultLanguage) => {
    this.props.onCountryChange(country, defaultLanguage);
    this.setState({ firstOpen: false });
  };

  render() {
    const {
      country,
      error,
      hasSetCurrencyError,
      hasVoucherError,
      isLoading,
      isOpen,
      noOfProductsInBag,
      onSubmit,
      regionalStore,
      referrer,
      formatTranslation
    } = this.props;

    return (
      <Fade isActive={isOpen} onExit={this.handleFocus}>
        <Backdrop />
        <Container
          onClose={this.handleClose}
          isLoading={isLoading}
          withError={hasVoucherError || hasSetCurrencyError}
        >
          {isLoading || country == null ? (
            <div className={styles.progressIndicatorContainer}>
              <ProgressIndicator
                size={SIZE_MEDIUM}
                progressMessage={formatTranslation(
                  "accessibility_label_loading"
                )}
              />
            </div>
          ) : (
            <FocusLock noFocusGuards={true} className={styles.inheritDisplay}>
              <InFocusGuard>
                <Form
                  {...country}
                  error={error}
                  hasSetCurrencyError={hasSetCurrencyError}
                  hasVoucherError={hasVoucherError}
                  referrer={referrer}
                  noOfProductsInBag={noOfProductsInBag}
                  onClose={this.handleClose}
                  onCountryChange={this.onCountryChange}
                  onSubmit={onSubmit}
                  regionalStore={regionalStore}
                  storeUrl={this.state.alternateUrls[country.lang]}
                  firstOpen={this.state.firstOpen}
                />
              </InFocusGuard>
            </FocusLock>
          )}
        </Container>
      </Fade>
    );
  }
}

CountrySelector.propTypes = {
  country: PropTypes.object,
  error: PropTypes.object,
  hasVoucherError: PropTypes.bool,
  hasSetCurrencyError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  noOfProductsInBag: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  regionalStore: PropTypes.shape({
    countryCode: PropTypes.string,
    storeCode: PropTypes.string,
    siteId: PropTypes.number,
    currency: PropTypes.string,
    language: PropTypes.string,
    countryName: PropTypes.string,
    sizeSchema: PropTypes.string
  }),
  focusLastActiveElement: PropTypes.func.isRequired,
  viewport: PropTypes.string.isRequired,
  referrer: PropTypes.string,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(CountrySelector);
