import withTranslation from "../../translation";
import PropTypes from "prop-types";
import styles from "./index.css";

const SkipToContent = ({ formatTranslation }) => (
  <a
    href="#chrome-app-container"
    className={styles.skipLink}
    data-testid="skip-to-content"
  >
    {formatTranslation("accessibility_skip_to_content")}
  </a>
);

SkipToContent.propTypes = {
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(SkipToContent);
