/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";

import withViewport from "@template/components/Viewport/withViewport";
import LargeSecondaryNavigation from "@template/components/Navigation/LargeSecondaryNavigation";
import {
  trackPageInteraction,
  getGender,
  ANALYTICS_INTERACTION_HOVER,
  ANALYTICS_INTERACTION_CLICK,
  ANALYTICS_POSITION_HEADER,
  ANALYTICS_CONTEXT
} from "@src/helpers/eventing/events";
import { FEMALE, genderPropType, MALE } from "@template/state/modules/layout";
import { navigationPropType } from "@template/state/modules/navigation";
import { keys } from "@template/globalEventHandler";
import TabOutside from "@template/components/TabOutside";
import withTranslation from "@template/components/translation";
import { NavigationContext } from "../NavigationContext";

import styles from "./index.css";

const cx = classnames.bind(styles);

const DOCUMENT_POSITION_CONTAINS = 8;
const HOVER_DELAY = 250;

class LargePrimaryNavigation extends Component {
  globalTrackingTimer = null;
  state = {
    isTouch: false,
    currentFocusedButton: null
  };

  isHidden = () => {
    return (
      !this.props.featuresLoaded ||
      this.props.forGender !== this.props.currentGender
    );
  };

  handleActiveChild = id => {
    this.props.setNavActiveItem(id);
  };

  getCurrentItemFromEvent(event) {
    const currentId = event.currentTarget.attributes["data-id"].value;
    return this.props.items.find(item => item.id === currentId);
  }

  handleClickEnterOrSpace = event => {
    const current = this.getCurrentItemFromEvent(event);
    this.props.clearNavActiveItem();

    if (current.id !== this.props.largeNavActiveItemId) {
      this.handleActiveChild(current.id);
      this.track(current.label, ANALYTICS_INTERACTION_CLICK);
    }
  };

  handleKeyDown = event => {
    const current = this.getCurrentItemFromEvent(event);

    if (
      event.key === keys.escape &&
      current.id === this.props.largeNavActiveItemId
    ) {
      this.props.clearNavActiveItem();
    }
  };

  handleMouseEnter = event => {
    const current = this.getCurrentItemFromEvent(event);
    this.delayedHover = setTimeout(() => {
      this.handleActiveChild(current.id);
      this.beginTracking(current.label);
    }, HOVER_DELAY);
  };

  handleMouseLeave = event => {
    if (this.state.isTouch) {
      event.preventDefault();
    } else {
      if (this.delayedHover) {
        clearTimeout(this.delayedHover);
        this.delayedHover = null;
      }
      this.clearTracking();
    }
  };

  handleTouchStart = () => {
    this.setState({ isTouch: true });
    setTimeout(() => {
      this.setState({ isTouch: false });
    }, 600);
  };

  track = (label, interaction) => {
    const context =
      interaction === ANALYTICS_INTERACTION_CLICK
        ? ANALYTICS_CONTEXT.openNav
        : ANALYTICS_CONTEXT.navHover;

    trackPageInteraction({
      context,
      interaction,
      elementText: label,
      properties: {
        positionOnPage: ANALYTICS_POSITION_HEADER,
        gender: getGender(this.props.forGender)
      }
    });
  };

  beginTracking = label => {
    this.globalTrackingTimer = setTimeout(
      () => this.track(label, ANALYTICS_INTERACTION_HOVER),
      2000
    );
  };

  clearTracking = () => {
    clearTimeout(this.globalTrackingTimer);
  };

  hasClickedInside = event =>
    (event.target.compareDocumentPosition(this.container) &
      DOCUMENT_POSITION_CONTAINS) !==
    0;

  handleFocusOnButton = id => {
    this.setState({ currentFocusedButton: id });
  };

  handleTabListEvents = event => {
    const { items } = this.props;
    const { currentFocusedButton } = this.state;
    const currentButton = items.find(({ id }) => id === currentFocusedButton);
    const currentIndex = items.findIndex(item => item === currentButton);

    let targetIndex;

    switch (event.key) {
      case "ArrowLeft": {
        targetIndex = currentIndex > 0 ? currentIndex - 1 : items.length - 1;
        break;
      }
      case "ArrowRight": {
        targetIndex = currentIndex < items.length - 1 ? currentIndex + 1 : 0;
        break;
      }
      case "Home": {
        event.preventDefault();
        targetIndex = 0;
        break;
      }
      case "End": {
        event.preventDefault();
        targetIndex = items.length - 1;
        break;
      }
      default: {
        return;
      }
    }

    const targetId = items[targetIndex].id;
    document.querySelector(`[data-id="${targetId}"]`).focus();
  };

  getNavAriaLabel = () => {
    return (
      (this.props.currentGender === MALE &&
        this.props.formatTranslation("header_mens_products_aria_label")) ||
      (this.props.currentGender === FEMALE &&
        this.props.formatTranslation("header_womens_products_aria_label"))
    );
  };

  render() {
    const { items, clearNavActiveItem, largeNavActiveItemId } = this.props;

    return (
      <NavigationContext.Provider value={clearNavActiveItem}>
        <TabOutside disabled={this.isHidden()} callback={clearNavActiveItem}>
          <nav
            aria-hidden={this.isHidden() ? true : null}
            aria-label={this.getNavAriaLabel()}
            className={cx([styles.container], {
              [styles.container__hidden]: this.isHidden()
            })}
            data-testid="primarynav-large"
            ref={container => (this.container = container)}
          >
            <div className={styles.navigation}>
              <div
                className={styles.navButtons}
                onKeyDown={this.handleTabListEvents}
              >
                {items &&
                  items.map((item, index) => {
                    const { id, label, largeScreenStyleType } = item;

                    return (
                      <Fragment key={id}>
                        <button
                          data-id={id}
                          data-index={index}
                          className={cx(styles.navButton, {
                            [styles.navButton__active]:
                              id === largeNavActiveItemId
                          })}
                          aria-expanded={id === largeNavActiveItemId}
                          aria-controls={id}
                          data-testid="primarynav-button"
                          onClick={this.handleClickEnterOrSpace}
                          onMouseEnter={this.handleMouseEnter}
                          onMouseLeave={this.handleMouseLeave}
                          onTouchStart={this.handleTouchStart}
                          onFocus={() => this.handleFocusOnButton(id)}
                          onKeyDown={this.handleKeyDown}
                        >
                          <span
                            className={cx(styles.navButton__label, {
                              [styles.slant]: largeScreenStyleType === "sale",
                              [styles.bold]: largeScreenStyleType === "premium"
                            })}
                          >
                            <span>{label}</span>
                          </span>
                        </button>
                        <div id={id} className={styles.secondaryNav}>
                          <LargeSecondaryNavigation
                            item={item}
                            hasClickedInside={this.hasClickedInside}
                          />
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          </nav>
        </TabOutside>
      </NavigationContext.Provider>
    );
  }
}

LargePrimaryNavigation.propTypes = {
  forGender: genderPropType.isRequired,
  currentGender: genderPropType.isRequired,
  items: PropTypes.arrayOf(navigationPropType).isRequired,
  clearNavActiveItem: PropTypes.func.isRequired,
  setNavActiveItem: PropTypes.func.isRequired,
  largeNavActiveItemId: PropTypes.string,
  isVisible: PropTypes.bool,
  featuresLoaded: PropTypes.bool,
  formatTranslation: PropTypes.func
};

export default withViewport(withTranslation(LargePrimaryNavigation));
