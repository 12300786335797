import PropTypes from "prop-types";
import Disappointed from "@asosteam/asos-web-component-library-svg-icons/lib/disappointed-emoji.svg";
import styles from "./deferred.css";
import withTranslation from "@template/components/translation";

const ErrorPage = ({ formatTranslation }) => (
  <div className={styles.main}>
    <img alt="" src={Disappointed} className={styles.face} />
    <h1 className={styles.heading}>
      {formatTranslation("generic_error_oops")}
    </h1>
    <p className={styles.para}>
      {formatTranslation("generic_error_technical_difficulties")}
    </p>
  </div>
);

ErrorPage.propTypes = {
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(ErrorPage);
