import PropTypes from "prop-types";
import withTranslation from "../../translation";
import styles from "./index.css";

const MinibagHeader = ({ totalQuantity, formatTranslation }) => (
  <h2 data-test-id="miniBagHeader" className={styles.container}>
    <span className={styles.bagTitle}>
      {formatTranslation("minibag_mybag")},
    </span>
    <span data-test-id="miniBagItemCount">
      {formatTranslation("minibag_itemcount", {
        smart_count: totalQuantity
      })}
    </span>
  </h2>
);

MinibagHeader.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  totalQuantity: PropTypes.number.isRequired
};

export default withTranslation(MinibagHeader);
