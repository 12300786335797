import { useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./deferred.css";
import withTranslation from "@template/components/translation";
import { MODAL_ANIMATION_TIME_MS } from "@template/components/Modals/constants";
import Close from "@asosteam/asos-web-component-library-svg-icons/lib/close.svg";

const CloseButton = ({
  formatTranslation,
  moveFocusToCloseButton,
  closeButtonClickDelegate
}) => {
  const closeButton = useCallback(
    node => {
      let timeout;
      if (moveFocusToCloseButton && node) {
        timeout = setTimeout(() => {
          node.focus();
        }, MODAL_ANIMATION_TIME_MS);
      }

      if (!node) {
        clearTimeout(timeout);
      }
    },
    [moveFocusToCloseButton]
  );

  return (
    <button
      className={styles.button}
      aria-label={formatTranslation("icon_close")}
      onClick={closeButtonClickDelegate}
      data-test-id="closeModal"
      ref={closeButton}
    >
      <img src={Close} alt="" className={styles.icon} />
    </button>
  );
};

CloseButton.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  moveFocusToCloseButton: PropTypes.bool.isRequired,
  closeButtonClickDelegate: PropTypes.func.isRequired
};

export default withTranslation(CloseButton);
