import PropTypes from "prop-types";
import withTranslation from "../../../translation";
import Button from "../../../../components/Button";
import { withParams } from "../../../../../helpers/queryString";

import styles from "./index.css";
import { APP_ROUTES } from "../../../../constants";

const BagLinks = ({
  defaultStoreUrl,
  formatTranslation,
  startCheckout,
  useMvtStyles
}) => {
  function linkWithAnalytics(link) {
    return withParams(link, { ctaref: "mini bag" });
  }
  return (
    <div className={useMvtStyles ? styles.mvtBagLinks : styles.bagLinks}>
      <div className={styles.buttonContainer}>
        <Button
          theme="secondary"
          href={linkWithAnalytics(defaultStoreUrl.concat(APP_ROUTES.BAG_LINK))}
          data-test-id="bag-link"
        >
          {useMvtStyles
            ? formatTranslation("icon_bag")
            : formatTranslation("minibag_footer_baglinks_bag_text")}
        </Button>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          theme="green"
          href="#"
          onClick={() => startCheckout()}
          data-test-id="checkout-link"
        >
          {formatTranslation("minibag_footer_baglinks_checkout_text")}
        </Button>
      </div>
    </div>
  );
};

BagLinks.propTypes = {
  defaultStoreUrl: PropTypes.string.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  startCheckout: PropTypes.func.isRequired,
  useMvtStyles: PropTypes.bool
};

export default withTranslation(BagLinks);
