import PropTypes from "prop-types";
import { addLanguage } from "./addLanguage";
import { buildTranslationFormatter } from "./buildTranslationFormatter";

const withTranslation = Component => {
  const ComposedComponent = ({ language, phrases, ...props }) => (
    <Component
      {...props}
      formatTranslation={buildTranslationFormatter(language, phrases)}
    />
  );

  ComposedComponent.propTypes = {
    language: PropTypes.string.isRequired,
    phrases: PropTypes.object.isRequired
  };
  ComposedComponent.displayName = `withTranslation(${
    Component.displayName || Component.name || "Component"
  })`;
  ComposedComponent.WrappedComponent = Component;
  ComposedComponent.initActions = Component.initActions;

  return addLanguage(ComposedComponent);
};

export default withTranslation;
