import PropTypes from "prop-types";
import classnames from "classnames/bind";
import withTranslation from "@template/components/translation";
import DeleteItem from "./DeleteItem";
import Price from "../Price";
import styles from "./deferred.css";
import formatImageUrl from "@template/helpers/formatImageUrl";
import { withParams } from "@src/helpers/queryString";
import { getAriaLabel } from "./getAriaLabel";
import {
  ITEM_TYPE_PRODUCT,
  ITEM_TYPE_VOUCHER,
  ITEM_TYPE_SUBSCRIPTION
} from "./constants";

const cx = classnames.bind(styles);

const getProductUrl = (images, size, type = "Standard1") => {
  const filteredImagesBySize = images.find(image => image.size === size);
  const filteredImagesByType = images.find(image => image.type === type);

  if (filteredImagesBySize) {
    return filteredImagesBySize.url;
  }

  if (filteredImagesByType) {
    return formatImageUrl(filteredImagesByType.url);
  }

  return null;
};

const containerClassNames = (itemType = "") =>
  cx("container", "item", itemType.toLowerCase());

const getItemId = (productId, subscriptionId, voucherStyleId) => {
  if (subscriptionId) return subscriptionId;
  if (voucherStyleId) return voucherStyleId;
  return productId;
};

const LineItem = ({
  colour,
  defaultStoreUrl,
  formatTranslation,
  id,
  productId,
  images,
  imageUrl,
  itemType,
  name,
  price,
  productUrl,
  quantity,
  size,
  subscriptionId,
  voucherStyleId
}) => {
  const voucherUrl = `${defaultStoreUrl}gift-vouchers/?edit=true`;
  const itemUrl = itemType === ITEM_TYPE_VOUCHER ? voucherUrl : productUrl;

  const linkWithAnalytics = withParams(itemUrl, {
    ctaref: "mini bag",
    featureref1: "mini bag"
  });

  const productImage = getProductUrl(images, "l");
  const largeProductImage = getProductUrl(images, "xl");

  const hasColour = !!colour && itemType === ITEM_TYPE_PRODUCT;
  const hasSize = !!size && itemType === ITEM_TYPE_PRODUCT;
  const itemId = getItemId(productId, subscriptionId, voucherStyleId);

  const ariaLabel = getAriaLabel({
    name,
    price,
    itemType,
    hasColour,
    colour,
    hasSize,
    size,
    quantity,
    formatTranslation
  });

  const WrapperTag = itemType === ITEM_TYPE_SUBSCRIPTION ? "div" : "a";
  const href = itemType === ITEM_TYPE_SUBSCRIPTION ? null : linkWithAnalytics;

  return (
    <div
      aria-label={formatTranslation("generic_accessibility_product")}
      className={containerClassNames(itemType)}
      role="group"
    >
      <WrapperTag className={styles.link} aria-label={ariaLabel} href={href}>
        {imageUrl && <img src={imageUrl} alt="" className={styles.image} />}
        {images.length > 0 && (
          <img
            src={productImage}
            srcSet={`${largeProductImage} 2x`}
            alt=""
            className={styles.image}
          />
        )}
        <dl className={styles.details}>
          <div className={styles.price}>
            <dt className={styles.srOnly}>
              {formatTranslation("minibag_price")}
            </dt>
            <dd>
              <Price {...price} />
            </dd>
          </div>
          <div className={styles.name}>
            <dt className={styles.srOnly}>
              {formatTranslation("minibag_name")}
            </dt>
            <dd>{name}</dd>
          </div>
          <div className={styles.meta}>
            {hasColour && (
              <div className={styles.colour}>
                <dt className={styles.srOnly}>
                  {formatTranslation("minibag_color")}
                </dt>
                <dd>{colour}</dd>
              </div>
            )}
            {hasSize && (
              <div className={styles.size}>
                <dt className={styles.srOnly}>
                  {formatTranslation("minibag_size")}
                </dt>
                <dd>{size}</dd>
              </div>
            )}
            <div className={styles.quantity}>
              <dt className={styles.term}>
                <abbr title={formatTranslation("minibag_quantity")}>
                  {formatTranslation("minibag_qty")}
                </abbr>
              </dt>
              <dd className={styles.quantity}>{quantity}</dd>
            </div>
          </div>
        </dl>
      </WrapperTag>
      <DeleteItem id={id} itemId={itemId} quantity={quantity} price={price} />
    </div>
  );
};

LineItem.propTypes = {
  colour: PropTypes.string,
  formatTranslation: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  productId: PropTypes.number,
  subscriptionId: PropTypes.number,
  voucherStyleId: PropTypes.number,
  itemType: PropTypes.oneOf([
    ITEM_TYPE_PRODUCT,
    ITEM_TYPE_VOUCHER,
    ITEM_TYPE_SUBSCRIPTION
  ]),
  imageUrl: PropTypes.string,
  images: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.string,
        url: PropTypes.string
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.string,
        type: PropTypes.string
      })
    )
  ]),
  name: PropTypes.string,
  price: PropTypes.shape({
    value: PropTypes.number,
    current: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number
    }),
    isMarkedDown: PropTypes.bool,
    isOutletPrice: PropTypes.bool,
    previous: PropTypes.shape({
      text: PropTypes.string
    }),
    rrp: PropTypes.shape({
      text: PropTypes.string
    })
  }),
  productUrl: PropTypes.string,
  quantity: PropTypes.number,
  size: PropTypes.string,
  defaultStoreUrl: PropTypes.string.isRequired
};

LineItem.defaultProps = {
  images: []
};

export default withTranslation(LineItem);
