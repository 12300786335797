import { MODALS_NAMESPACE_KEY } from "../constants";
import { MODAL_TYPES } from "../../constants";
import { validateOpenModalParams } from "../../Modal/validateOpenModalParams";
import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@template/constants";

const openDivModalFunctionName = "openDivModal";
const modalsNamespace = `${asosGlobalNamespace}.${siteChromeGlobalNamespace}.${MODALS_NAMESPACE_KEY}`;
const prependModalsNamespace = namespace => `${modalsNamespace}.${namespace}`;

const mandatoryParametersMessage = `
'${openDivModalFunctionName}' must be called with an object that has the following properties:
  - 'onClosed': delegate called when the modal is closed (perhaps after animation delay).
  - 'modalType': one of the following(from '${prependModalsNamespace(
    "MODAL_TYPES"
  )}') : ${Object.keys(MODAL_TYPES).join(", ")}.
`;

export const validateOpenDivModalParams = params => {
  if (
    !(params && typeof params === "object" && Object.keys(params).length > 0)
  ) {
    throw new Error(mandatoryParametersMessage);
  }

  const errorParams = validateOpenModalParams(params);

  const { onClosed } = params;

  if (!(onClosed instanceof Function)) {
    errorParams.push("'onClosed'");
  }

  if (errorParams.length > 0) {
    throw new Error(`"The following parameters were missing or invalid when calling '${prependModalsNamespace(
      openDivModalFunctionName
    )}': ${errorParams.join(", ")}.
  ${mandatoryParametersMessage}`);
  }
};
