import PropTypes from "prop-types";
import {
  MALE,
  FEMALE,
  LARGE_VIEWPORT,
  viewportPropType
} from "../../state/modules/layout";
import withTranslation from "../translation";
import LargeFloorNavigation from "./LargeFloorNavigation";
import SmallFloorNavigation from "./SmallFloorNavigation";

const isSelectedFloor = ({ floor, isWomensFloor, isMensFloor, viewport }) => {
  if (floor === MALE) {
    return isMensFloor;
  }

  if (viewport === LARGE_VIEWPORT) {
    return isWomensFloor;
  }

  return !isMensFloor;
};

const FloorNavigation = props => {
  const {
    formatTranslation,
    isMensFloor = false,
    isWomensFloor = false,
    menStoreUrl,
    setFloor,
    viewable,
    viewport,
    womenStoreUrl
  } = props;

  if (!viewable.includes(viewport)) {
    return null;
  }

  const isMensFloorSelected = isSelectedFloor({
    floor: MALE,
    isWomensFloor,
    isMensFloor,
    viewport
  });

  const isWomensFloorSelected = isSelectedFloor({
    floor: FEMALE,
    isWomensFloor,
    isMensFloor,
    viewport
  });

  if (viewport === LARGE_VIEWPORT) {
    return (
      <LargeFloorNavigation
        formatTranslation={formatTranslation}
        isMensFloorSelected={isMensFloorSelected}
        isWomensFloorSelected={isWomensFloorSelected}
        menStoreUrl={menStoreUrl}
        womenStoreUrl={womenStoreUrl}
      />
    );
  }

  return (
    <SmallFloorNavigation
      formatTranslation={formatTranslation}
      isMensFloorSelected={isMensFloorSelected}
      isWomensFloorSelected={isWomensFloorSelected}
      setFloor={setFloor}
    />
  );
};

FloorNavigation.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  isWomensFloor: PropTypes.bool,
  isMensFloor: PropTypes.bool,
  setFloor: PropTypes.func,
  menStoreUrl: PropTypes.string,
  viewable: PropTypes.arrayOf(viewportPropType).isRequired,
  viewport: viewportPropType,
  womenStoreUrl: PropTypes.string
};

export default withTranslation(FloorNavigation);
