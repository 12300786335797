import { connect } from "react-redux";
import PropTypes from "prop-types";
import HtmlHeader from "@template/components/HtmlHeader";
import Breadcrumbs from "@template/components/Breadcrumbs";
import Footer from "@template/components/Footer";
import WelcomeMessage from "@template/components/WelcomeMessage";
import InfoBanner from "@template/components/InfoBanner";
import SidePanel from "@template/components/Navigation/SidePanel";
import CountrySelector from "@template/components/CountrySelector";
import SecureScriptContainer from "@template/components/Footer/SecureScriptContainer";
import Modals from "@template/components/Modals";
import { isMainAppAriaHidden } from "@template/state/modules/layout/selectors";
import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT
} from "@template/state/modules/layout";
import { BREADCRUMBS_CONTAINER_ID } from "@template/../client/breadcrumbs/constants";

const MainContentComponent = ({ isMainAppAriaHidden, webContext }) => (
  <div
    aria-hidden={isMainAppAriaHidden ? true : null}
    className="chrome-main-content--accessible"
  >
    <div id="chrome-welcome-mat">
      <WelcomeMessage />
    </div>
    <div id="chrome-header">
      <HtmlHeader />
    </div>
    <div id={BREADCRUMBS_CONTAINER_ID}>
      <Breadcrumbs />
    </div>
    <main
      id="chrome-app-container"
      dangerouslySetInnerHTML={{ __html: "<!--%applicationContent%-->" }}
    />
    <div id="chrome-footer">
      <Footer />
      <SecureScriptContainer webContext={webContext} />
    </div>
    <div id="chrome-info-banner">
      <InfoBanner />
    </div>
  </div>
);

const sidePanelViewable = [SMALL_VIEWPORT, MEDIUM_VIEWPORT];

const ModalContentComponent = ({ isMainAppAriaHidden }) => (
  <div
    id="chrome-modal-container"
    aria-hidden={!isMainAppAriaHidden ? true : null}
  >
    <SidePanel viewable={sidePanelViewable} />
    <CountrySelector />
    <Modals />
  </div>
);

ModalContentComponent.propTypes = {
  isMainAppAriaHidden: PropTypes.bool
};

MainContentComponent.propTypes = {
  isMainAppAriaHidden: PropTypes.bool,
  webContext: PropTypes.object
};

const mapStateToProps = state => ({
  isMainAppAriaHidden: isMainAppAriaHidden(state)
});
export const ModalContent = connect(mapStateToProps)(ModalContentComponent);
export const MainContent = connect(mapStateToProps)(MainContentComponent);
