import { Fragment } from "react";
import PropTypes from "prop-types";
import LineItem from "./LineItem";
import Notification from "../../components/Notification";
import withTranslation from "../translation";
import ReservationMessage from "./ReservationMessage";
import TransitionList from "../TransitionList";

import styles from "./index.css";
import { APP_ROUTES } from "../../constants";

import {
  ProgressIndicator,
  SIZE_MEDIUM
} from "@asosteam/asos-web-component-library-progress-indicator";

const firstTwentyItems = items => items.slice(0, 20);

const MiniBag = ({
  defaultStoreUrl,
  bagItemDeleteTransitionEnd,
  formatTranslation,
  items,
  lang,
  loading
}) => {
  const moreThanTwentyItems = items.length > 20;
  const loaded = items.length && !loading;
  const itemsToRender = firstTwentyItems(items);
  const showMessagePartOne = () => {
    const languagesWithoutMessagePartOne = ["nl-NL", "sv-SE", "it-IT"];
    return !languagesWithoutMessagePartOne.includes(lang);
  };

  return loaded ? (
    <Fragment>
      <ReservationMessage />
      <div className={styles.miniBag}>
        <TransitionList
          onTransitionEnd={bagItemDeleteTransitionEnd}
          itemDeletedLabel={formatTranslation("minibag_itemdeleted")}
        >
          {itemsToRender.map(item => (
            <LineItem
              key={item.id}
              {...item}
              defaultStoreUrl={defaultStoreUrl}
            />
          ))}
        </TransitionList>
        {moreThanTwentyItems && (
          <Notification type="alert">
            <div className={styles.notification}>
              <span>
                {formatTranslation("minibag_footer_maximum_items_message")}
              </span>
              <span>
                {showMessagePartOne() &&
                  formatTranslation("minibag_footer_view_bag_message_part_one")}
                <a
                  className={styles.link}
                  href={defaultStoreUrl.concat(APP_ROUTES.BAG_LINK)}
                >
                  {formatTranslation("minibag_footer_view_bag_message_link")}
                </a>
                {formatTranslation(
                  "minibag_footer_view_bag_message_part_two"
                ).replace(/^([^,])/, " $1")}
              </span>
            </div>
          </Notification>
        )}
      </div>
    </Fragment>
  ) : (
    <div className={styles.progressIndicatorContainer}>
      <ProgressIndicator
        size={SIZE_MEDIUM}
        progressMessage={formatTranslation("accessibility_label_loading")}
      />
    </div>
  );
};

MiniBag.propTypes = {
  defaultStoreUrl: PropTypes.string.isRequired,
  bagItemDeleteTransitionEnd: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  items: PropTypes.array,
  lang: PropTypes.string,
  loading: PropTypes.bool
};

MiniBag.defaultProps = {
  items: [],
  loading: false
};

export default withTranslation(MiniBag);
