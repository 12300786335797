/* eslint-disable jsx-a11y/role-supports-aria-props */
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FocusLock, { InFocusGuard } from "react-focus-lock";
import FocusWithin from "react-focus-within";
import clientConfig from "@template/clientConfig";
import { SMALL_VIEWPORT } from "@template/state/modules/layout/constants";
import {
  globalEventHandler,
  events,
  keys,
  keyCodes
} from "@template/globalEventHandler";
import { getResultItemId } from "../SearchDropdown/getResultItemId";
import withTranslation from "@template/components/translation";
import SearchIcon from "@template/components/SearchIcon";
import IconButton from "@template/components/IconButton";
import SearchDropdown from "@template/components/SearchDropdown";
import SearchControls from "./SearchControls";
import ItemAnnouncer from "./ItemAnnouncer";
import SearchResultsCountAnnouncer from "./SearchResultsCountAnnouncer";
import styles from "./SearchForm.css";
import BlockScroll from "@src/helpers/blockScroll";
import { triggerNavigation } from "../../../client/navigationBroker";

const cx = classNames.bind(styles);

const SEARCH_SUGGESTIONS_DEBOUNCE_INTERVAL = 300;
const MAX_CHARACTER_VALUE_FOR_SEARCH = "150";

const SearchForm = ({
  closeSearch,
  formatTranslation,
  hasVisibleResults,
  isVisible,
  loadSuggestions,
  onSearch,
  openSearch,
  searchUrl,
  setSearchTerm,
  term,
  items,
  isSuggestions,
  viewport,
  lastActiveElements,
  focusLastActiveElement
}) => {
  const [unformattedTerm, setUnformattedTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);
  const blockScroll = useRef();

  useEffect(() => {
    blockScroll.current = new BlockScroll();
  }, []);

  useEffect(() => {
    if (clientConfig.isServer()) {
      return;
    }

    if (isVisible) {
      blockScroll.current.enable();
      focusInput();
    } else {
      blockScroll.current.disable();
    }
  }, [isVisible]);

  useEffect(() => {
    const closeSearchOverlay = globalEventHandler.addListener(
      events.closeSearchOverlay,
      handleFormBlur
    );

    const globalKeyDownListener = globalEventHandler.addListener(
      events.keyDown,
      event => {
        if (
          event.key === keys.escape &&
          formRef.current.contains(document.activeElement)
        ) {
          handleClose();
        }
      }
    );

    setSearchTermIfPresentOnMount();

    return () => {
      closeSearchOverlay.remove();
      globalKeyDownListener.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSearchTermIfPresentOnMount = () => {
    if (term.trim().length > 0) {
      setSearchTerm(term);
    }
  };

  const updateSearchTerm = term => {
    setActiveIndex(-1);

    setSearchTerm(term);
    setUnformattedTerm(term);
  };

  const loadSuggestionsTimer = useRef(null);

  const triggerLoadSuggestions = () => {
    clearTimeout(loadSuggestionsTimer.current);

    loadSuggestionsTimer.current = setTimeout(
      loadSuggestions,
      SEARCH_SUGGESTIONS_DEBOUNCE_INTERVAL
    );
  };

  const formRef = useRef(null);
  const inputRef = useRef(null);

  const [shouldSubmitForm, setShouldSubmitForm] = useState(false);
  useEffect(() => {
    if (shouldSubmitForm) {
      const isFullPageNavigationCancelled = handlePageNavigation();
      if (isFullPageNavigationCancelled) {
        handleClose();
        setShouldSubmitForm(false);
      } else {
        formRef.current?.submit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmitForm]);

  const handleDropdownSelect = term => {
    updateSearchTerm(term);
    onSearch(term);
    setShouldSubmitForm(true); // ensures form is submitted after search term is updated in state and input field
  };

  const handleInputChange = event => {
    handleFormFocus();
    updateSearchTerm(event.target.value);
    triggerLoadSuggestions();
  };

  const handleClose = () => {
    updateSearchTerm("");

    closeSearch({
      isModal: viewport === SMALL_VIEWPORT
    });

    viewport === SMALL_VIEWPORT
      ? focusLastActiveElement(lastActiveElements)
      : focusInput();
  };

  const handleFormSubmit = event => {
    if (!term.length) {
      event?.preventDefault();

      return;
    }

    updateSearchTerm(term);
    onSearch(term);

    const isFullPageNavigationCancelled = handlePageNavigation();
    if (isFullPageNavigationCancelled) {
      event?.preventDefault();
      handleClose();
    }
  };

  const handlePageNavigation = () => {
    const url = new URL(formRef.current.action);
    url.search = new URLSearchParams(new FormData(formRef.current)).toString();
    return triggerNavigation(url.href);
  };

  const handleFormFocus = () => {
    openSearch({ isModal: viewport === SMALL_VIEWPORT });
  };

  const handleFormBlur = () => {
    setActiveIndex(-1);

    if (viewport !== SMALL_VIEWPORT) {
      closeSearch({ isModal: false });
    }
  };

  const handleInputKeyDown = event => {
    const itemCount = items.length;
    let tempActiveIndex = activeIndex;

    switch (event.keyCode) {
      case keyCodes.up: {
        handleFormFocus();

        if (tempActiveIndex <= 0) {
          tempActiveIndex = itemCount - 1;
        } else {
          tempActiveIndex--;
        }

        break;
      }
      case keyCodes.down: {
        handleFormFocus();

        if (tempActiveIndex === -1 || tempActiveIndex >= itemCount - 1) {
          tempActiveIndex = 0;
        } else {
          tempActiveIndex++;
        }

        break;
      }
      case keyCodes.enter: {
        if (tempActiveIndex !== -1) {
          updateSearchTerm(items[tempActiveIndex].searchTerm);
        }

        return;
      }
      default: {
        return;
      }
    }

    event.preventDefault();
    setActiveIndex(tempActiveIndex);
  };

  const focusInput = () => {
    setTimeout(() => inputRef.current?.focus());
  };

  const [inputFocused, setInputFocused] = useState(false);
  const handleInputFocus = () => setInputFocused(true);
  const handleInputBlur = () => setInputFocused(false);

  const handleClearOnClick = () => {
    updateSearchTerm("");
    triggerLoadSuggestions();
    focusInput();
  };

  const renderSearchNode = () => {
    const buttonClassName = cx(styles.button, {
      [styles.button__active]: term
    });

    const resultsClassName = cx(styles.results, {
      [styles.results__active]: hasVisibleResults
    });

    const containerClassName = cx(styles.container, {
      [styles.container__active]: isVisible
    });

    const backdropClassName = cx(styles.backdrop, {
      [styles.backdrop__active]: isVisible
    });

    const fieldClassName = cx(styles.field, {
      [styles.field__active]: isVisible
    });

    const activeDescendant =
      activeIndex === -1 ? null : getResultItemId(activeIndex);

    return (
      <div
        className={styles.searchContainer}
        onTouchMove={event => event.preventDefault()}
      >
        <div
          className={backdropClassName}
          data-testid="search-overlay-shadow"
          aria-hidden="true"
          role="presentation"
          onClick={handleFormBlur}
        />
        <FocusWithin onFocus={handleFormFocus} onBlur={handleFormBlur}>
          {({ focusProps }) => (
            <form
              action={searchUrl}
              className={containerClassName}
              data-testid="search-form"
              method="get"
              ref={formRef}
              onSubmit={handleFormSubmit}
              {...focusProps}
            >
              <div className={styles.fieldContainer} data-testid="search-field">
                <span
                  className={styles.floatingLabel}
                  aria-hidden={!term ? true : null}
                >
                  {term && `${formatTranslation("icon_search")}:`}
                </span>
                <label
                  id="chrome-search-label"
                  htmlFor="chrome-search"
                  hidden={true}
                >
                  {formatTranslation("searchbar_label")}
                </label>
                <input
                  id="chrome-search"
                  name="q"
                  className={fieldClassName}
                  type="search"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                  placeholder={formatTranslation("searchbar_placeholder")}
                  data-testid="search-input"
                  ref={inputRef}
                  aria-autocomplete="list"
                  aria-haspopup="listbox"
                  aria-controls={hasVisibleResults ? "search-results" : null}
                  aria-expanded={hasVisibleResults}
                  aria-activedescendant={activeDescendant}
                  maxLength={MAX_CHARACTER_VALUE_FOR_SEARCH}
                  value={unformattedTerm}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                />
                {term && (
                  <IconButton
                    className={styles.field__clear}
                    icon={styles.close}
                    data-testid="search-clear-button"
                    aria-label={formatTranslation(
                      "accessibility_search_clear_text_button_description"
                    )}
                    onClick={handleClearOnClick}
                  />
                )}
                <button
                  className={buttonClassName}
                  type="submit"
                  disabled={!term}
                  data-testid="search-button-inline"
                >
                  <SearchIcon />
                </button>
                <div className={resultsClassName}>
                  <ItemAnnouncer activeIndex={activeIndex} items={items} />
                  <SearchDropdown
                    activeIndex={activeIndex}
                    focusSearchBox={focusInput}
                    isSuggestions={isSuggestions}
                    items={items}
                    term={term}
                    onSelect={handleDropdownSelect}
                  />
                  <SearchResultsCountAnnouncer shouldAnnounce={inputFocused} />
                </div>
              </div>
              <SearchControls viewable={[SMALL_VIEWPORT]}>
                <IconButton
                  height={50}
                  icon={styles.close}
                  aria-label={formatTranslation("icon_close")}
                  onClick={handleClose}
                />
              </SearchControls>
            </form>
          )}
        </FocusWithin>
      </div>
    );
  };

  return (
    (!clientConfig.isServer() &&
      isVisible &&
      viewport === SMALL_VIEWPORT &&
      ReactDOM.createPortal(
        <div aria-label="Search" role="dialog" aria-modal="true">
          <FocusLock noFocusGuards={true}>
            <InFocusGuard>{renderSearchNode()}</InFocusGuard>
          </FocusLock>
        </div>,
        document.getElementById("chrome-modal-container")
      )) ||
    renderSearchNode()
  );
};

SearchForm.propTypes = {
  closeSearch: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  hasVisibleResults: PropTypes.bool,
  isVisible: PropTypes.bool,
  loadSuggestions: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  openSearch: PropTypes.func.isRequired,
  searchUrl: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  term: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      searchTerm: PropTypes.string.isRequired,
      numberOfResults: PropTypes.number
    })
  ).isRequired,
  isSuggestions: PropTypes.bool.isRequired,
  viewport: PropTypes.string.isRequired,
  lastActiveElements: PropTypes.arrayOf(PropTypes.string),
  focusLastActiveElement: PropTypes.func
};

SearchForm.defaultProps = {
  isVisible: false,
  hasVisibleResults: false
};

export default withTranslation(SearchForm);
