import { trackOneTrustEvents } from "@src/helpers/eventing/events.js";
import { getIsCookieBannerShowing } from "./getIsCookieBannerShowing";

const getConsentStates = () => ({
  customerPreferenceCookies: window.OnetrustActiveGroups.includes("C0003"),
  targetingOrAdvertisingCookies: window.OnetrustActiveGroups.includes("C0004")
});

export const trackAnalytics = () => {
  // Cookie Banner is conditionally in the DOM
  if (getIsCookieBannerShowing()) {
    const thatsOkButton = document.getElementById(
      "onetrust-accept-btn-handler"
    );

    thatsOkButton?.addEventListener("click", () => {
      trackOneTrustEvents({
        consentStatus: "allow all",
        ...getConsentStates()
      });
    });
  }

  // Preference Center is currently always in the DOM
  const allowAllButton = document.getElementById(
    "accept-recommended-btn-handler"
  );
  const allowSelectedOnlyButton = document.querySelector(
    ".save-preference-btn-handler"
  );

  allowSelectedOnlyButton.addEventListener("click", () => {
    trackOneTrustEvents({
      consentStatus: "allow selected only",
      ...getConsentStates()
    });
  });

  allowAllButton.addEventListener("click", () => {
    trackOneTrustEvents({
      consentStatus: "allow all",
      ...getConsentStates()
    });
  });
};
