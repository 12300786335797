import { useEffect, useState, useRef } from "react";
import { MODAL_TYPES, MODAL_ANIMATION_TIME_MS } from "../constants";

export const useRenderManagement = ({
  isOpen,
  modalType,
  forceDOMRewrite,
  onClosedDelegate
}) => {
  const [shouldRenderModal, setShouldRenderModal] = useState(isOpen);
  const [shouldRenderPageOverlay, setShouldRenderPageOverlay] =
    useState(isOpen);

  const derenderTimeout = useRef(null);
  const firstRender = useRef(true);

  useEffect(() => {
    const derenderNow = () => {
      setShouldRenderModal(!forceDOMRewrite);
      setShouldRenderPageOverlay(false);
      onClosedDelegate();
    };

    const derenderWithAnimationDelay = () => {
      clearTimeout(derenderTimeout.current);
      derenderTimeout.current = setTimeout(() => {
        derenderNow();
      }, MODAL_ANIMATION_TIME_MS);
    };

    const manageDerender = () => {
      modalType === MODAL_TYPES.SIDE_RIGHT
        ? derenderWithAnimationDelay()
        : derenderNow();
    };

    if (firstRender.current !== true) {
      if (!isOpen) {
        manageDerender();
      } else {
        clearTimeout(derenderTimeout.current);
        setShouldRenderPageOverlay(true);
        setShouldRenderModal(true);
      }
    } else {
      setShouldRenderPageOverlay(false);
      setShouldRenderModal(false);
    }
  }, [isOpen, modalType, forceDOMRewrite]);

  useEffect(() => {
    firstRender.current = false;
  }, []);
  return { shouldRenderModal, shouldRenderPageOverlay };
};
