/* eslint-disable no-underscore-dangle */
import { Component } from "react";
import PropTypes from "prop-types";
import {
  getMyAccountBaseUrl,
  getIdentitySdkAuthHost
} from "@template/helpers/externalLinks";
import { withParams } from "@src/helpers/queryString";
import withTranslation from "../translation";
import withViewport from "../Viewport/withViewport";
import styles from "./index.css";
import { isWindowDefined } from "@src/helpers/url";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace,
  APP_ROUTES
} from "@template/constants";

const getGreetingMessage = (isKnown, firstName, formatTranslation) =>
  isKnown && (
    <span className={styles.welcome}>
      {formatTranslation("myaccountlinks_greeting", { name: firstName })}
    </span>
  );

const getButtonLinks = (
  isLoggedIn,
  isRecognised,
  signUpLink,
  signInLink,
  formatTranslation
) => {
  const identitySdk =
    isWindowDefined() &&
    window[asosGlobalNamespace] !== undefined &&
    window[asosGlobalNamespace][sdkGlobalNamespace] !== undefined &&
    window[asosGlobalNamespace][sdkGlobalNamespace].identity;

  if (!isLoggedIn && !isRecognised) {
    return (
      <span className={styles.links}>
        <a
          className={styles.link}
          href={signInLink}
          data-testid="signin-link"
          onClick={e => {
            if (!identitySdk) {
              return;
            }
            e.preventDefault();
            identitySdk.login();
          }}
        >
          {formatTranslation("myaccountlinks_sign_in_label")}
        </a>
        <div className={styles.pipe} aria-hidden="true" />
        <a className={styles.link} href={signUpLink} data-testid="signup-link">
          {formatTranslation("myaccountlinks_sign_up_label")}
        </a>
      </span>
    );
  }

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <span className={styles.links}>
      <button
        className={styles.link}
        onClick={e => {
          e.preventDefault();
          identitySdk && identitySdk.logout();
        }}
        data-testid="signout-link"
      >
        {formatTranslation("myaccountlinks_sign_out_label")}
      </button>
    </span>
  );
};

class Greeter extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isRecognised: PropTypes.bool,
    formatTranslation: PropTypes.func.isRequired,
    firstName: PropTypes.string,
    lang: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    storeCode: PropTypes.string.isRequired,
    keyStoreDataversion: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      returnUrl: false
    };
  }

  componentDidMount() {
    this.setState({
      returnUrl: window.location.href
    });
  }

  render() {
    const {
      isLoggedIn,
      isRecognised,
      firstName,
      formatTranslation,
      lang,
      countryCode,
      storeCode,
      keyStoreDataversion
    } = this.props;

    const params = {
      lang,
      store: storeCode,
      country: countryCode,
      keyStoreDataversion
    };

    const signUpBaseUrl = `${getIdentitySdkAuthHost()}/${
      APP_ROUTES.MY_ACCOUNT_SIGNUP_LINK
    }`;

    const signUpLink = withParams(signUpBaseUrl, {
      ...params,
      returnUrl: this.state.returnUrl
    });

    const signInLink = withParams(getMyAccountBaseUrl(), params);

    return (
      <div className={styles.container}>
        {getGreetingMessage(
          isLoggedIn || isRecognised,
          firstName,
          formatTranslation
        )}
        {getButtonLinks(
          isLoggedIn,
          isRecognised,
          signUpLink,
          signInLink,
          formatTranslation
        )}
      </div>
    );
  }
}

export default withViewport(withTranslation(Greeter));
